import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';

export interface ICallablesMagicCodesGetForCustomerData {
  userId: number;
}

@Injectable({
  providedIn: 'root'
})
export class MagicCodesService {

  constructor(
    private dbService: DbService,
  ) { }

  public verifyMagicCode(uuid: string) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesMagicCodesVerify, data: uuid });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForCustomer(data: ICallablesMagicCodesGetForCustomerData): Observable<string> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesMagicCodesGetForCustomer, data: data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
