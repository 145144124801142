import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inflection',
  standalone: true
})
export class InflectionPipe implements PipeTransform {

  constructor() {}

  transform(length: number | null | undefined, translationString: string): string {
    if (length === null || length === undefined) {
      return '';
    }
    length = Math.abs(length);
    switch (true) {
      case (length === 1):
        return `${translationString}.one`;
      case (length > 1 && length < 5):
        return `${translationString}.few`;
      case (length >= 5 || length === 0):
        return `${translationString}.many`;
      default:
        return `${translationString}.many`;
    }
  }
}
