import { Injectable, OnDestroy } from '@angular/core';
import { User } from '@angular/fire/auth';
import { BehaviorSubject, finalize, Subscription, take, tap } from 'rxjs';
import { IUser } from '../../models/user/user.model';
import { AuthService } from '../auth.service';
import { IUserUpdateAccountData, UsersService } from '../entities/users/users.service';
import { NavigationService } from '../navigation.service';
import { UtilsService } from '../utils.service';
import { StoreService } from './store.service';
import { RoleType } from '../../enums/user/role-types.enum';
import { I18nService, LanguageChangePriority } from '../i18n.service';
import { ActionBy } from '../../enums/utils/action-by.enum';

export interface IUserState {
  user$: BehaviorSubject<IUser | null>;

  fetchingUser$: BehaviorSubject<boolean>;
  updatingUser$: BehaviorSubject<boolean>;
  initialized$: BehaviorSubject<boolean>;
};

@Injectable({
  providedIn: 'root'
})
export class UserStoreService implements OnDestroy {

  private initState: IUserState = {
    user$: new BehaviorSubject<IUser | null>(null),
    fetchingUser$: new BehaviorSubject<boolean>(false),
    updatingUser$: new BehaviorSubject<boolean>(false),
    initialized$: new BehaviorSubject<boolean>(false)

  };
  public state: IUserState = this.utilsService.initializeState(this.initState) as IUserState;
  private userSub: Subscription = Subscription.EMPTY;
  private actionSubs: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private utilsService: UtilsService,
    private usersService: UsersService,
    private store: StoreService,
    private navService: NavigationService,
    private i18nService: I18nService,
  ) {
    this.userSub = this.authService.afUser$.subscribe(afUser => {
      this.init(afUser);
    });

    this.actionSubs.push(
      // user updated
      this.store.actions.user_userUpdated$.subscribe(user => {
        this.state.user$.next({ ...this.state.user$.getValue(), ...user });
      }),
    )
  }

  private init(afUser: User | null | undefined) {
    this.utilsService.resetState(this.initState, this.state);

    if (afUser === undefined) return;
    if (afUser === null) {
      this.state.initialized$.next(true);
      return;
    };

    this.state.fetchingUser$.next(true);
    this.usersService.getUserForStore().pipe(
      take(1),
      finalize(() => this.state.fetchingUser$.next(false))
    ).subscribe({
      next: (res) => {
        // console.log('User:', res)
        this.state.user$.next(res);
        if (res) {
          this.state.initialized$.next(true);
          if (res.preferredLanguage) this.i18nService.useLanguage(res.preferredLanguage, LanguageChangePriority._1);
  
          if (this.navService.wantedUrl) {
            this.navService.goToPrevWantedUrl();
          } else if (this.authService.redirectToDashboardAfterUserLoad) {
            this.authService.redirectToDashboardAfterUserLoad = false;
            this.navService.goToUsersDashboard(res);
          }
        }
      }
    });
  }

  public updateUserAccount(data: IUserUpdateAccountData) {
    this.state.updatingUser$.next(true);
    return this.usersService.updateAccount(data).pipe(
      take(1),
      finalize(() => this.state.updatingUser$.next(false)),
      tap((res) => {
        this.store.actions.user_userUpdated$.next(res);
      })
    );
  }

  public hasUserRole(role: RoleType, orgId: number | null) {
    const user = this.state.user$.getValue();
    if (!user) return null;
    return user.roles.some(r => r.type === role && r.organizationId === orgId);
  }
  public hasAnyUserRole(roles: RoleType[], orgId: number | null) {
    const user = this.state.user$.getValue();
    if (!user) return null;
    return user.roles.some(r => roles.includes(r.type) && r.organizationId === orgId);
  }


  ngOnDestroy(): void {
    this.userSub.unsubscribe();
    this.actionSubs.forEach(s => s.unsubscribe());
  }
}
