import { Injectable, OnDestroy } from '@angular/core';
import { Auth, getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, User } from '@angular/fire/auth';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppMessageService } from './app-message.service';
import { NavigationService } from './navigation.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  afUser$ = new BehaviorSubject<User | null | undefined>(undefined);

  subs: Subscription[] = [];

  public redirectToDashboardAfterUserLoad: boolean = false;

  constructor(
    private auth: Auth,
    private appMessagingService: AppMessageService,
    private navService: NavigationService,
    private utilsService: UtilsService,
  ) {
    onAuthStateChanged(getAuth(), async (afUser) => {
      if (this.afUser$.value && !afUser) {
        this.navService.goToSignIn();
      }
      // console.log('AfUser:', afUser)
      this.afUser$.next(afUser);
    });

  }

  public isAuthenticated(): boolean {
    return !!this.afUser$.getValue();
  }

  public signIn(email: string, password: string) {
    const p = signInWithEmailAndPassword(this.auth, email, password);
    p.catch((err) => {
      this.handleEmailSignInError(err);
    });
    return p;
  }

  public signOut(withoutRedirect?: boolean) {
    return this.auth.signOut().then(() => {
      if (!withoutRedirect) {
        this.navService.goToSignIn();
      }
    });
  }

  public async signInWithToken(token: string, redirect: boolean = true) {
    await this.signOut(true);
    this.redirectToDashboardAfterUserLoad = redirect;
    return signInWithCustomToken(this.auth, token).then((res) => {
    });
  }

  public sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this.auth, email).then((res) => {
      this.appMessagingService.successMessage({message: 'auth.reset-password-sent'});
    }).catch((err) => {
      this.utilsService.logError(err);
      this.appMessagingService.errorMessage({message: 'auth.reset-password-error'});
    });
  }


  /**
   * HELP HERE: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
   */
  private handleEmailSignInError(err: any) {
    switch (err.code) {
      case 'auth/user-not-found':
        this.appMessagingService.errorMessage({ message: 'auth.user-not-found'});
        break;
      case 'auth/wrong-password':
        this.appMessagingService.errorMessage({ message: 'auth.wrong-password'});
        break;
      case 'auth/invalid-login-credentials':
      case 'auth/invalid-credential':
        this.appMessagingService.errorMessage({ message: 'auth.invalid-login-credentials'});
        break;
      case 'auth/too-many-requests':
        this.appMessagingService.errorMessage({ message: 'auth.too-many-requests'});
        break;
      default:
        this.utilsService.logError(err);
        this.appMessagingService.errorMessage({ message: 'auth.sign-in-error'});
        break;
    }
  }


  ngOnDestroy(): void {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
