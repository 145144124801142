import { AbstractControl } from "@angular/forms";

// TODO: move elsewhere?
export function emailValidator(control: AbstractControl) {
  if (!(typeof control.value === 'string')) return null;

  const email = (control.value as string | null)?.trim();

  // this is not required validator
  if (!email) return null;

  return isEmailValid(email) ? null : { email: true }
}

export function isEmailValid(email: string) {
  const regex = new RegExp(/^(?:(?:[a-zA-z0-9_%+-][a-zA-z0-9._%+-]*?[a-zA-z0-9_%+-])|(?:[a-zA-z0-9_%+-]))@(?:(?:[a-zA-z0-9][a-zA-z0-9.-]*?[a-zA-z0-9])|(?:[a-zA-z0-9]))\.[a-zA-z]{2,63}$/gm);
  email = email.trim();

  if (!regex.test(email)) {
    return false;
  }
  if (email.length > 253) {
    return false;
  }
  if (email.split('@')[0].length > 64) {
    return false;
  }
  return true;
}
