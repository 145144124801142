import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IInviteUnregisteredMemberData } from '../../models/organization/members/invite-unregistered-member-data.model';
import { IRemoveOrgMemberData } from '../../models/organization/members/remove-org-member-data.model';
import { IOrganization } from '../../models/organization/organization.model';
import { ICoordinator } from '../../models/user/coordinator/coordinator.model';
import { ILecturer } from '../../models/user/lecturer/lecturer.model';
import { IUser } from '../../models/user/user.model';

@Injectable({
  providedIn: 'root'
})

/**
 * MOZNA BUDE PUSOBIT NEKDY CIRCULAR DEPENDENCY ...
 */
export class StoreService {

  public actions = {
    // user
    user_userUpdated$: new Subject<IUser>(),
    //

    // eventAdmin
    eventAdmin_archiveEvent$: new Subject<number>(),
    eventAdmin_eventArchived$: new Subject<number>(),
    eventAdmin_reinit$: new Subject<void>(),
    //

    // lecturers
    lecturers_lecturersFetched$: new Subject<ILecturer[]>(),
    //

    // coordinators
    coordinators_coordinatorsFetched$: new Subject<ICoordinator[]>,
    //

    // organization
    organization_organizationUpdated$: new Subject<IOrganization | null>, // null = unknown
    organization_updateOrganizationInState$: new Subject<void>,
    //

    // orgUsers
    orgUsers_removeMember$: new Subject<IRemoveOrgMemberData>(),
    orgUsers_inviteUnregistered$: new Subject<IInviteUnregisteredMemberData>(),

    orgUsers_memberAdded$: new Subject<IUser>(),
    orgUsers_memberUpdated$: new Subject<{ userId: number, user: IUser | null }>(),
    orgUsers_memberRemoved$: new Subject<number>(),
    //

    // customerOrders
    customerOrders_reinit$: new Subject<void>(),
    //

    // customerReservationSummaries
    customerReservationSummaries_reinit$: new Subject<void>(),
    //

    // adminShopItem
    adminShopItem_shopItemUpdated$: new Subject<{ id: number, uuid: string }>(),
    adminShopItem_eventUpdated$: new Subject<void>(),
    adminShopItem_shopItemDuplicated$: new Subject<{ id: number, uuid: string }[]>(),
    //

    // new shopItem modal
    newShopItemModal_shopItemCreated$: new Subject<{ id: number, uuid: string }>(),
    //

    // session attendance
    sessionAttendance_sessionAttendanceChanged$: new Subject<void>(),
    //

    // orgReservations
    orgReservation_reservationChangedShopItem$: new Subject<number>(),
    //

    // copy session modal
    copySessionModal_sessionCopied$: new Subject<{sessionId: number}>(),
    //

    // session card
    sessionCard_sessionChanged$: new Subject<{ sessionId: number }>(),
    //

    // sgLecturerHourRateModal
    sgLecturerHourRateModal_lecturerHourRateChanged$: new Subject<void>(),
    //

    // siSessionsModule
    siSessionsModule_reinitSessions$: new Subject<void>(),
    //

    // siSgLecturersModule
    siSgLecturersModule_reinitSgLecturers$: new Subject<void>(),
    //

    // siSiCoordinatorsModule
    siSiCoordinatorsModule_reinitCoordinators$: new Subject<void>(),
    //

    // siDiscountsFormSection
    siDiscountsFormSection_reinitDiscounts$: new Subject<void>(),
    //

    // siAccreditationsFormSection
    siAccreditationsFormSection_reinitAccreditations$: new Subject<void>(),
    //

    // siCategoriesModule
    siCategoriesModule_reinitCategories$: new Subject<void>(),
    //
    
    // siAutomaticCommunicationModule
    siAutomaticCommunicationModule_automaticCommunicationsUpdated$: new Subject<void>(),
    //

    // eventDiscountsFormSection
    eventDiscountsFormSection_reinitDiscounts$: new Subject<void>(),
    //

    // siCertificateIssueModal
    siCertificateIssueModal_certificatesIssued$: new Subject<{ reservationIds: number[] }>(),
    //

    // siPublicTagsModule
    siPublicTagsModule_reinitPublicTags$: new Subject<void>(),
    //

    // reservationTableFilters
    reservationTableFilters_filtersChanged$: new Subject<void>(),
    // /
    
    // siInternalTagsModule
    siInternalTagsModule_reinitInternalTags$: new Subject<void>(),
    //
  };

  constructor() { }
}
