import { Injectable } from '@angular/core';
import { IOrgUser, IUser } from '../../models/user/user.model';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {

  constructor(
    private utilsService: UtilsService
  ) { }

  public getOrgUserFullName(user: (Partial<IUser> & { orgUsers: IOrgUser[]; }), organizationId: number | null) {
    const orgUser = this.getOrgUser(user, organizationId);
    return this.getFullName({ name: orgUser?.name ?? null, surname: orgUser?.surname ?? null });
  }

  public getFullName(d: { name: string | null; surname: string | null; }) {
    return `${d.name ?? ''} ${d.surname ?? ''}`;
  }

  public getRFC822Email(user: Partial<IOrgUser> | null) {
    if (user === null || !user.email) {
      return '';
    }
    return `${user.name} ${user.surname} <${user.email}>`;
  }

  public getOrgUser(user: (Partial<IUser> & { orgUsers: IOrgUser[]; }), organizationId: number | null) {
    if (!organizationId) {
      console.error('No organizationId found! qwfqwfqwfqw');
      this.utilsService.logError('No organizationId found! qwfqwfqwfqw');
      return null;
    }
    return user.orgUsers.find((orgUser) => orgUser.organizationId === organizationId);
  }

  public getOrgUserName(user: (Partial<IUser> & { orgUsers: IOrgUser[]; }), organizationId: number | null) {
    const orgUser = this.getOrgUser(user, organizationId);
    return {
      name: orgUser?.name || '',
      surname: orgUser?.surname || '',
    };
  }
  
  public getOrgUserFirstName(user: (Partial<IUser> & { orgUsers: IOrgUser[]; }), organizationId: number | null) {
    const orgUser = this.getOrgUser(user, organizationId);
    return orgUser?.name || '';
  }
  
  public getOrgUserSurname(user: (Partial<IUser> & { orgUsers: IOrgUser[]; }), organizationId: number | null) {
    const orgUser = this.getOrgUser(user, organizationId);
    return orgUser?.surname || '';
  }
}
